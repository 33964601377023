import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

import { useTranslation, Trans } from 'react-i18next';


const lngs = {
  en: { nativeName: 'English' },
  zh: { nativeName: '中文' }
};

function IndexNavbar() {

    //for i18n
    const { t, i18n } = useTranslation();
    const selectLang=()=>{
      if(i18n.language==='en' ){
        i18n.changeLanguage('zh');
      }
      else{
        i18n.changeLanguage('en');
      }
    
    }
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="dark">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand
              href="https://strongroot.ca"
              target="_blank"
              id="navbar-brand"
            >
              STRONG ROOT
            </NavbarBrand>
            <UncontrolledTooltip target="#navbar-brand">
              A canada company.Make you stronger!
            </UncontrolledTooltip>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink                                    
                  type="submit"
                  onClick={selectLang}
                >
                  <i className="dev-ui-icons objects_globe"></i>
                  <p>{t('navbar.english')}</p>
                </NavLink>

              </NavItem>
              <NavItem>
                <NavLink
                >
                  <a href="https://www.strongroot.ca/"
                  target="_blank">
                  <i className="dev-ui-icons location_pin"></i>
                  <p>{t('navbar.home')}</p>
                  </a>
                </NavLink>
              </NavItem>
              {      /*
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  href="https://www.strongroot.ca/#products"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="dev-ui-icons design_app mr-1"></i>
                  <p>{t('navbar.products')}</p>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem 
                                      href="https://www.strongroot.ca/#products"
                                      target="_blank">
                    <i className="dev-ui-icons business_chart-pie-36 mr-1"></i>
                    {t('navbar.allproducts')}
                  </DropdownItem>
                  <DropdownItem
                    href="https://www.strongroot.ca/#products"
                    target="_blank"
                  >
                    <i className="dev-ui-icons design_bullet-list-67 mr-1"></i>
                    <p>{t('navbar.comingsoon')}</p>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>

              <NavItem>
                <NavLink
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      document
                        .getElementById("download-section")
                        .scrollIntoView();
                    }}
                  >
                    <i className="dev-ui-icons files_single-copy-04"></i>
                    <p>{t('navbar.verifyproduct')}</p>
                  </NavLink>
              </NavItem>
        


              <NavItem>
                <NavLink
                  href="https://wechat.com/strongroot?ref=strongroot"
                  target="_blank"
                  id="wechat-tooltip"
                >
                  <i className="fab fa-weixin"></i>
                  <p className="d-lg-none d-xl-none">WeChat</p>
                </NavLink>
                <UncontrolledTooltip target="#wechat-tooltip">
                  Follow us on WeChat
                </UncontrolledTooltip>
              </NavItem>
*/
              }


            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
