import React from "react";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import ProfilePageHeader  from "components/Headers/ProfilePageHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";

// sections for this page

import VerifyProduct from "./index-sections/VerifyProduct.js";
//import ProductsList from "components/products-list.component";

import { Suspense } from 'react';

function Index() {


  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <ProfilePageHeader />
        <div className="main"> 
        <VerifyProduct />
        </div>
        <DarkFooter />
      </div>
    </>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...is loading">
      <Index />
    </Suspense>
  );
}
