/*eslint-disable*/
import React from "react";
import { HashLink as Link } from 'react-router-hash-link';

// reactstrap components
import { Container } from "reactstrap";
import { useTranslation, Trans } from 'react-i18next';

const lngs = {
  en: { nativeName: 'English' },
  zh: { nativeName: '中文' }
};

function DarkFooter() {

      //for i18n
      const { t, i18n } = useTranslation();
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
            <li>
              
              <a
                href="https://www.strongroot.ca"
                target="_blank"
              >
                Strong Root
              </a>
            </li>
            <li>
            <a
                href="https://www.strongroot.ca"
                target="_blank"
              >
                {t('navbar.home')}
              </a>
            </li>
{/*  
            <li>
              <a
                href="https://www.strongroot.ca/#products"
                target="_blank"
              >
                {t('navbar.products')}
              </a>
            </li>

            
                        <li>
              <a
                href="#"
                target="_blank"
              >
                {t('navbar.verifyproduct')}
              </a>
            </li>
            
            */}

          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, {" "}
          <a
            href="https://www.strongroot.ca"
            target="_blank"
          >
            Strong Root Pharmaceutical Ltd.
          </a>
          .
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
