import React, { Component } from "react";

// reactstrap components
import { Button, Container, Row, Col, Input, UncontrolledTooltip } from "reactstrap";
import { connect } from "react-redux";
import { retrieveProducts, findProductsByTitle, findProductsByBarcode, deleteAllProducts } from "actions/products";
import { Link } from "react-router-dom";
// core components
import { useTranslation, Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';
const lngs = {
  en: { nativeName: 'English' },
  zh: { nativeName: '中文' }
};


class VerifyProduct extends React.Component {
    constructor(props) {
      super(props);
      this.onChangeSearchTitle = this.onChangeSearchTitle.bind(this);
      this.onChangeSearchBarcode = this.onChangeSearchBarcode.bind(this);
      this.refreshData = this.refreshData.bind(this);
      this.setActiveProduct = this.setActiveProduct.bind(this);
      this.findByTitle = this.findByTitle.bind(this);
      this.findByBarcode = this.findByBarcode.bind(this);
      this.removeAllProducts = this.removeAllProducts.bind(this);
  
      this.state = {
        currentProduct: null,
        currentIndex: -1,
        searchTitle: "NOT a product From Strong Root!",
        searchBarcode: "0",
      };
    }
  
    componentDidMount() {
    //  this.props.retrieveProducts();
    }
  
    onChangeSearchTitle(e) {
      const searchTitle = e.target.value;
  
      this.setState({
        searchTitle: searchTitle,
      });
    }
    onChangeSearchBarcode(e) {
      const searchBarcode = e.target.value;
  
      this.setState({
        searchBarcode: searchBarcode,
      });
    }
  
    
  
    refreshData() {
      this.setState({
        currentProduct: null,
        currentIndex: -1,
      });
    }
  
    setActiveProduct(product, index) {
      this.setState({
        currentProduct: product,
        currentIndex: index,
      });
    }
  
    removeAllProducts() {
      this.props
        .deleteAllProducts()
        .then((response) => {
          console.log(response);
          this.refreshData();
        })
        .catch((e) => {
          console.log(e);
        });
    }
  
    findByTitle() {
      this.refreshData();
  
      this.props.findProductsByTitle(this.state.searchTitle);
    }
  
    findByBarcode(){
      this.refreshData();
      this.props.findProductsByBarcode(this.state.searchBarcode);
    }
    
    render() {
      const { searchTitle,searchBarcode, currentProduct, currentIndex } = this.state;
      const { products } = this.props;

      const { t } = this.props;
    

  return (
    <>
      <div
        className="section section-download"
        data-background-color="black"
        id="download-section"
      >
        <Container>
          <Row className="justify-content-md-center">
            <Col className="text-center" lg="8" md="12">
              <h3 className="title">{t('veriprod.inputbarcode')}</h3>
              <h5 className="description">
              {t('veriprod.toverify')}
              </h5>
            </Col>
            <Col className="text-center" lg="6" md="12">
                {/* <div className="input-group mb-3"> */} 
                    <Input
                    type="text"
                   // className="form-control"
                    placeholder="Search by barcode"
                    value={searchBarcode}
                    onChange={this.onChangeSearchBarcode}
                    />
                 {/*  <div className="input-group-append"> */} 
                    <Button 
                        className="btn-round" 
                        color="info" 
                        type="button"
                        onClick={this.findByBarcode}
                    >
                        {t('veriprod.search')}
                    </Button>
                     {/* </div> */} 
               {/* </div> */} 
            </Col>
          </Row>

          <Row className="text-center mt-5">
            <Col className="ml-auto mr-auto" md="8">
            {products.length>0 ? (
              products.map((product, index) => (
                <>
                <h4>{t('veriprod.result')}</h4>
                  <ul className="list-group">                      
                    <li
                        className={
                        "list-group-item " +
                        (index === currentIndex ? "active" : "list-group-item-info")
                        }
                        onClick={() => this.setActiveProduct(product, index)}
                        key={index}
                    >
                      {t('veriprod.searchedbarcode')}: {product.barcode}<br/>
                        {/*product.title*/ }{t('veriprod.right')}-{t('content.product.kiworna')}
                    </li>
                  </ul>
                <h3>{t('veriprod.thanks')}!</h3>
              </>
            )) ): (
              <div>
              <br />
              <p>{t('veriprod.searchedbarcode')}: {searchBarcode}</p>
              <h3>{t('veriprod.tobuyrightprod')}!</h3>
              </div>
            )}

            </Col>
            <Col md="12">
                {currentProduct  ? (
                <div>  
                  <div>
                      <label>
                      <strong>{t('general.itis')} {t('veriprod.right')}.</strong>                    
                      </label>
                  </div>
                  <div>
                      <label>
                      <strong>{t('veriprod.barcode')}:</strong>
                      </label>{" "}
                      {currentProduct.barcode}
                  </div>

                  <Link
                      to={"/products/" + currentProduct.id}
                      className="badge badge-warning"
                  >                    
                  </Link>
                  <br></br>
                  
                </div>
                ) :(
                  <h5><br /></h5>
                ) }
            </Col>
          </Row>
          <br></br>
          <br></br>
          {/*
                    <Row className="justify-content-md-center sharing-area text-center">
            <Col className="text-center" lg="8" md="12">
              <h3>{t('veriprod.thanks')}!</h3>
            </Col>
            <Col className="text-center" lg="8" md="12">

            </Col>
          </Row>
          
          */}

        </Container>
      </div>
    </>
  );
}
}

const mapStateToProps = (state) => {
    return {
      products: state.products,
    };
  };
  
export default connect(mapStateToProps, { retrieveProducts, findProductsByTitle, findProductsByBarcode, deleteAllProducts })(withTranslation()(VerifyProduct));