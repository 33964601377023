/*
=========================================================
* Designed by www.vivoleo.com Coded by www.vivoleo.com
=========================================================
*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/vivoleo-dev.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";

import { Provider } from 'react-redux';
import store from './store';
import { HashRouter } from "react-router-dom";
import './i18n';

ReactDOM.render(
  <Provider store={store}>
    {/* <BrowserRouter> */}
    
    <HashRouter>
      <Switch>
        <Switch>
          <Route path="/index" render={(props) => <Index {...props} />} />          
          <Redirect to="/index" />
          <Redirect from="/" to="/index" />
        </Switch>
      </Switch>
    </HashRouter>
    {/* </BrowserRouter> */}
    


  </Provider>,

  document.getElementById("root")
);
